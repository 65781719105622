import React from 'react';

export const Input = ({ type, value, onChangeFunc }) => {
  return (
    <input
      type={type}
      style={{ display: "table-cell", width: "100%" }}
      value={value}
      onChange={e => onChangeFunc(e.target.value)}
    />
  )
}