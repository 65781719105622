import React from 'react';
import { Input } from '../Input/Input';
import { Button } from "react-bootstrap";
import "../../../src/index.css";

export const Form = ({ onSubmitFunc, formContent }) => {
  return (
    <form
      onSubmit={onSubmitFunc}
      className='page-content page-content-small'
    >
      <div className='form-wrapper'>
        {formContent.map((elem, i) => (
          <div key={i}>
            <h6 className='inputHeading'>{elem.heading}</h6>
            <Input 
              type={elem.type} 
              value={elem.value}
              onChangeFunc={elem.func}
            />
          </div>
        ))}
      </div>
      <Button className='light-green-button btn-block' type='submit'>
        Log In
      </Button>
    </form>
  )
}