import { navigate, Link } from "gatsby";
import React, { useState } from "react";
import {
  RealmAppProvider,
  useRealmApp,
  FACILITATOR_APP_ID,
} from "../../../components/realm_app";
import OnboardingRedirector from "../../../components/facilitator/onboarding_redirector";
import { validateEmail, validatePassword } from "../../../utils";
import Navbar from "../../../components/onboarding_navbar";
import { Form } from '../../../components/Form/Form';
import '../../../../src/index.css';

const SignupPage = ({ location }) => {
  var [ticket, url_email] = ["", ""];
  if (typeof window !== "undefined") {
    const params = new URL(location.href).searchParams;
    ticket = params.get("ticket");
    ticket = ticket == null ? "" : ticket;
    url_email = params.get("email");
  }

  const [name, setName] = useState("");
  const [email, setEmail] = useState(url_email ? url_email : "");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [loading, setLoading] = useState("");
  const realmApp = useRealmApp();

  //TODO: Think of better place to store this data
  const formContent = [
    {'heading': 'Full Name:', 'type': 'text', 'value': name, 'func': setName},
    {'heading': 'Email:', 'type': 'email', 'value': email, 'func': setEmail},
    {'heading': 'Password:', 'type': 'password', 'value': password, 'func': setPassword},
    {'heading': 'Retype password:', 'type': 'password', 'value': passwordConfirmation, 'func': setPasswordConfirmation}
  ];

  async function logOutAll() {
    console.log("logging out all users");
    var i = 0;
    while (realmApp.app.currentUser != null && i < 100) {
      try {
        await realmApp.LogOut();
      } catch (e) {}
      i = i + 1;
      console.log([
        "current_user after one iteration of logging out",
        realmApp.app.currentUser,
      ]);
    }
    console.log(["current_user after logging out", realmApp.app.currentUser]);
  }

  async function setMongoName() {
    await realmApp.app.currentUser
      .mongoClient("mongodb-atlas")
      .db("test_db")
      .collection("Facilitator")
      .updateOne(
        { _id: realmApp.app.currentUser?.customData._id },
        { $set: { name: name } }
      );
  }

  async function tryRegister(name, email, password, ticket) {
    // make sure a fresh anonymous user exists to be able to call functions
    await logOutAll();
    await realmApp.LogInAnonymous();
    var user = realmApp.app.currentUser;
    console.log([
      "current_user",
      realmApp.app.currentUser,
      realmApp.app.currentUser.customData,
    ]);

    // validate the signup url
    // var valid_ticket = true;
    console.log(["validate args", email, name, ticket]);
    var valid_ticket =
      ticket != ""
        ? await user.functions.validateSignupTicket(email, name, ticket)
        : false;
    if (!valid_ticket) {
      await logOutAll();
      throw (
        "There was a problem detected with your unique signup link. Please " +
        "make sure you’re using the exact link from the email we sent you, " +
        "or contact us for support."
      );
    }

    // signup is valid. Remove the anonymous user(s)
    await logOutAll();
    await realmApp.Register(email, password);
  }

  async function handleSignUp(event) {
    event.preventDefault();
    setLoading("registering...");

    var validated = true;
    try {
      validateEmail(email);
      validatePassword(password);
    } catch (err) {
      console.log(err);
      alert(err);
      validated = false;
    }

    if (validated) {
      if (passwordConfirmation !== password) {
        alert("Passwords do not match, please try again.");
      } else {
        try {
          console.log(ticket);
          await tryRegister(name, email, password, ticket);
          await realmApp.LogIn(email, password);
          // set name of the newly created facilitator
          await setMongoName();
          navigate("/facilitator/dashboard/");
        } catch (err) {
          if (err.errorCode == "AccountNameInUse") {
            alert(`Email already in use.`);
          } else {
            console.log(JSON.stringify(err));
            alert(`error: ${err}`);
          }
        }
      }
    }

    setLoading("");
  }

  return (
    <main className='page'>
      <Navbar />
      <div className='page-content-center'>
        <h3>Create A Facilitator Account</h3>
      </div>
      <div className='page-content-center'>
        <div className='page-content page-content-small'>
          <Form onSubmitFunc={handleSignUp} formContent={formContent} />
        </div>
      </div>
      {loading}
      <h6 className='page-content-center'>
        Already have an account? <Link className='link' to='/facilitator/login/'>Log In</Link>
      </h6>
    </main>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={FACILITATOR_APP_ID}>
      <OnboardingRedirector location={location}>
        <SignupPage location={location} />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
