import React from "react";
import { useRealmApp, tryRedirect } from "../realm_app";

const signedOutPaths = [
  "/facilitator/login",
  "/facilitator/signup",
  "/facilitator/reset_password",
  "/facilitator/forgot_password",
];

const profilePicturePath = "/facilitator/signup/profile_picture";
const nameCirclePath = "/facilitator/signup/name_circle";

export default ({ location, children }) => {
  const realmApp = useRealmApp();
  React.useEffect(() => {
    if (realmApp.app.currentUser) {
      console.log("refreshing custom data");
      realmApp.appRefreshCustomData();
    }
  }, []);

  const conditioned_onboarding_paths = [
    [
      !realmApp.appCurrentUser?.customData.hasProfilePicture,
      profilePicturePath,
    ],
    [!realmApp.appCurrentUser?.customData.hasNamedCircle, nameCirclePath],
  ];

  const trimmedPath =
    location?.pathname.slice(-1) == "/"
      ? location?.pathname.slice(0, -1)
      : location?.pathname;

  // all paths associated with onboarding
  var onboarding_paths = conditioned_onboarding_paths.map((p) => p[1]);
  // onboarding paths still need to be completed
  var needed_onboarding_paths = conditioned_onboarding_paths
    .filter((p) => p[0])
    .map((p) => p[1]);
  // currently visiting an onboarding path as part of onboarding
  const is_onboarding =
    new URL(
      location.href ? location.href : "http://place.holder"
    ).searchParams.get("state") == "onboarding" &&
    onboarding_paths.includes(trimmedPath);

  console.log(`paths are ${location?.pathname} --- ${trimmedPath}`);
  console.log(`is_onboarding: ${is_onboarding}`);

  if (
    !realmApp.appCurrentUser ||
    Object.keys(realmApp.appCurrentUser.customData).length == 0
  ) {
    // no logged in user or logged in but on anonymous user
    if (!signedOutPaths.includes(trimmedPath)) {
      return tryRedirect("/facilitator/login/");
    } else {
      return <>{children}</>;
    }
  } else if (needed_onboarding_paths.length > 0) {
    // needs onboarding, but isn't onboarding.
    if (!is_onboarding) {
      return tryRedirect(needed_onboarding_paths[0], true);
    } else {
      return <>{children}</>;
    }
  } else if (is_onboarding) {
    // is onboarding, but onboarding is not needed. Go to dashboard.
    return tryRedirect("/facilitator/dashboard");
  } else if (
    realmApp.appCurrentUser &&
    Object.keys(realmApp.appCurrentUser.customData).length > 0 &&
    signedOutPaths.includes(trimmedPath)
  ) {
    // there is a user, but they're in a logged-out path
    return tryRedirect("/facilitator/dashboard");
  } else {
    return <>{children}</>;
  }
};
